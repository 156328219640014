import React from 'react'
import { Helmet } from 'react-helmet'
import Template from '../components/Template'
import * as style from './style.module.scss'

const PageAbout = () => (
  <Template>
    <div className={style.pageWrapper}>
      <Helmet>
        <title>About - 🐶 doggos.com</title>
      </Helmet>

      <h2>About</h2>
      <p>
        We love dogs. Doggos.com publishes <strong>FREE</strong> resources for
        dog owners built on the worlds fastest web technology.
      </p>
      <p className={style.amazonDisclosure}>
        This website participates in the Amazon affiliates program, meaning we
        may earn money when you click certain links.
      </p>
      <p>
        Questions? Please{' '}
        <a title="Email bork@doggos.com" href="mailto:bork+about@doggos.com">
          contact us.
        </a>
      </p>
    </div>
  </Template>
)

export default PageAbout
